<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Density of Liquids and Solids - Report Sheet Questions</h2>

      <p class="mb-n3">
        a) Why does the graduated cylinder have to be clean and dry before every trial? Choose the
        <b>best</b> answer.
      </p>

      <v-radio-group
        v-model="inputs.whyGradCylinderDryClean"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio
          v-for="option in optionsCleanDryGradCylinder"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) Why must you use the same balance each time you make a measurement? Choose the
        <b>best</b> answer.
      </p>

      <v-radio-group v-model="inputs.reasonSameBalance" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsSameBalance"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">
        c) The digital readout on the balance keeps jumping from
        <number-value :value="massValue" />
        to
        <latex-number :number="secondMass.toFixed(3)" />. What is the correct number to record in
        your lab notebook?
      </p>

      <calculation-input
        v-model="inputs.massToRecord"
        class="mb-1"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'OleMissExperiment3ReportSheetQuestions',
  components: {StembleLatex, CalculationInput, LatexNumber, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        whyGradCylinderDryClean: null,
        reasonSameBalance: null,
        massToRecord: null,
      },
      optionsCleanDryGradCylinder: [
        {
          text: "So you won't contaminate the sample with dirt or extra hydrogen.",
          value: 'contamination',
        },
        {
          text: "It's just good laboratory technique to start that way.",
          value: 'goodTechnique',
        },
        {
          text: 'So that the change in mass can be due only to the added water',
          value: 'massChangeDueToWaterOnly',
        },
        {
          text: 'Even a few extra drops of water could result in significant error in the density calculations',
          value: 'dropsAffectVolume',
        },
      ],
      optionsSameBalance: [
        {
          text: 'So any error in the balance will be systematic and cancel itself out.',
          value: 'systematicErrorCancelling',
        },
        {text: "So you won't get confused about your data.", value: 'avoidConfusion'},
        {
          text: "So the TA can tell who cleaned (or didn't clean) their station.",
          value: 'blamePeople',
        },
        {
          text: 'So the wear and tear on any one balance will be split among all the students.\n',
          value: 'evenWearAndTear',
        },
      ],
    };
  },
  computed: {
    massValue() {
      return this.taskState.getValueBySymbol('massValue').content;
    },
    secondMass() {
      return this.massValue.toFloat() + 0.001;
    },
  },
};
</script>
<style scoped></style>
